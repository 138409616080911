
import { defineComponent } from 'vue'
import Main from '@/templates/Main.vue'
import AddPerfectSection from '@/components/sections/AddPerfectSection.vue'

export default defineComponent({
  name: 'AddPerfect',
  components: {
    Main,
    AddPerfectSection
  }
})
