
import { defineComponent } from 'vue'
import MainButton from '@/components/atoms/buttons/MainButton.vue'

export default defineComponent({
  name: 'AddPerfectSection',
  data () {
    return {
      translateData: {}
    }
  },
  components: {
    MainButton
  },
  mounted () {
    this.translate()
  },
  methods: {
    translate (data) {
      data = []
      data.push('deposit_no_pm', 'add_new')
      this.$store.dispatch('getPayments/translate', data).then(
        (response) => {
          this.translateData = response
        },
        (error) => {
          console.log(error)
        }
      )
    },
    goToAddWallet () {
      this.$router.push('/dashboard/new-wallet')
    }
  }
})
